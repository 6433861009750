const FORMATTED_ADDR_REGEX = /^.*\s.+,\s.+,\s.+\s.+$/;

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

// Takes an address string and returns an object to be set as addressDetails state value
const parseFormattedAddress = addr => {
  if (!FORMATTED_ADDR_REGEX.test(addr)) return { invalid: true };

  const split = addr.split(', ');
  const streetAddressAndApt = split[0];
  const city = split[1].trim();
  const [state, zip] = split[2]
    .trim()
    .split(/\s+/)
    .map(str => str.trim());

  return {
    streetAddress: streetAddressAndApt,
    apt: '',
    city,
    state,
    zip,
  };
};

export { parseFormattedAddress };

export default capitalize;
