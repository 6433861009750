/** @module global/request/restaurant */
import RequestUtility from './request';

const SERVICE_URL = 'https://j3kkhlv7s2.execute-api.us-east-1.amazonaws.com/default';
const util = new RequestUtility(SERVICE_URL);
const ENDPOINT = 'CarouselImages';
const FOLDER_NAME = 'CarouselImages';

function parseDoc(doc) {
  const keys = doc.querySelectorAll('Key');
  return keys;
}

async function getEntries(bearerToken) {
  let orderingsPromise;
  let response;

  try {
    orderingsPromise = await util.authorizedRequest(`${ENDPOINT}/ENTRIES_ORDERING.json`, {
      method: 'GET',
      bearerToken,
    });
  } catch (error) {
    console.error('Failed to fetch orderings:', error);
    return [];
  }

  try {
    response = await util.authorizedRequestXml(ENDPOINT, {
      method: 'GET',
      bearerToken,
    });
  } catch (error) {
    console.error('Failed to fetch entries:', error);
    return [];
  }

  const keys = Array.from(parseDoc(response));
  const jsonDict = {};

  keys.forEach(key => {
    const path = key.textContent;
    const dotSeparatedPath = path.split('.');
    const extension = dotSeparatedPath[dotSeparatedPath.length - 1];
    if (extension.toLowerCase() !== 'json') {
      return;
    }

    const slashSeparatedPath = path.split('/');
    if (slashSeparatedPath[0] !== FOLDER_NAME || slashSeparatedPath[1] !== 'Live') {
      return;
    }

    const endpointSuffix = [...slashSeparatedPath].splice(1, slashSeparatedPath.length).join('%2F');
    const name = slashSeparatedPath.splice(2, slashSeparatedPath.length).join('/');

    try {
      jsonDict[name] = util.authorizedRequest(`${ENDPOINT}/${endpointSuffix}`, {
        method: 'GET',
        bearerToken,
      });
    } catch (error) {
      console.error(`Failed to request data for ${name}:`, error);
    }
  });

  let orderingsValue;
  try {
    orderingsValue = await orderingsPromise;
  } catch (error) {
    console.error('Failed to resolve orderings:', error);
    return [];
  }

  const orderedPromises = [];
  orderingsValue.forEach(entryName => {
    const promise = jsonDict[entryName];
    if (!promise) {
      return;
    }
    orderedPromises.push(promise);
  });

  let orderedJsonEntries;
  try {
    orderedJsonEntries = await Promise.all(orderedPromises);
  } catch (error) {
    console.error('Failed to resolve ordered JSON entries:', error);
    return [];
  }

  return orderedJsonEntries;
}

export default getEntries;
