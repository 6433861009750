export const getRandomDateFromLastWeek = () => {
  const today = new Date();
  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const randomDate = new Date(lastWeek.getTime() + Math.random() * (today.getTime() - lastWeek.getTime()));
  return randomDate.toLocaleDateString();
};

export const generateTestNotifications = () => {
  const today = new Date().toLocaleDateString();
  const lastWeek = getRandomDateFromLastWeek();

  return {
    notification1: {
      id: '1',
      sender: 'Filip',
      message: 'has challenged you to a FoodFight',
      time: today,
      classifyTime: today,
    },
    notification2: {
      id: '2',
      sender: 'Troy',
      message: 'just beat you in a FoodFight against KC.',
      time: lastWeek,
      classifyTime: lastWeek,
    },
    notification3: {
      id: '3',
      sender: 'FoodFight',
      message: 'The game is about to start',
      time: lastWeek,
      classifyTime: lastWeek,
    },
    notification4: {
      id: '4',
      sender: 'Troy',
      message: 'challenged you to a FoodFight',
      time: '08/02/24',
      classifyTime: '08/02/24',
    },
  };
};

export const generateTestChats = () => {
  const today = new Date().toLocaleDateString();
  const lastWeek = getRandomDateFromLastWeek();

  return {
    chat1: {
      id: '5',
      sender: 'Filip',
      message: 'Hey do you want to FoodFight?',
      time: today,
      classifyTime: today,
    },
    chat2: {
      id: '6',
      sender: 'Troy',
      message: 'Good game!',
      time: lastWeek,
      classifyTime: lastWeek,
    },
    chat3: {
      id: '7',
      sender: 'Filip',
      message: 'Same time next week?',
      time: lastWeek,
      classifyTime: lastWeek,
    },
    chat4: {
      id: '8',
      sender: 'Troy',
      message: 'Awww',
      time: '08/02/24',
      classifyTime: '08/02/24',
    },
  };
};
