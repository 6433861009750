import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Geolocation } from '@capacitor/geolocation';
import { isPlatform } from '@ionic/react';
import useGoogleMapsLoader from './useGoogleMapsLoader';
import FoodFightIcon from '../assets/foodfight_red_primary_05.png';
import CustomMapsPopup from './CustomMapsPopup';

const RestaurantMap = ({ restaurants }) => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(true);
  const [mapCenter, setMapCenter] = useState({ lat: 37.0902, lng: -95.7129 });
  const mapRef = useRef(null);
  const geocoderRef = useRef(null);
  const userMarkerRef = useRef(null);
  const restaurantMarkersRef = useRef([]);
  const { isLoaded, loadError } = useGoogleMapsLoader();

  useEffect(() => {
    if (isLoaded) {
      geocoderRef.current = new window.google.maps.Geocoder();
    }
  }, [isLoaded]);

  const getCurrentLocation = async (attemptsLeft = 3) => {
    if (attemptsLeft === 0) {
      console.error('Max retries reached for getting current location.');
      return;
    }

    try {
      if (isPlatform('hybrid')) {
        const permissionStatus = await Geolocation.checkPermissions();
        if (permissionStatus.location === 'denied') {
          console.log('Requesting geolocation permissions...');
          const requestStatus = await Geolocation.requestPermissions();
          if (requestStatus.location === 'denied') {
            console.log('Location permission denied.');
            setIsGeolocationEnabled(false);
            return;
          }
        }

        const position = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 30000,
        });

        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }); // Set map center to user's current position
      } else if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setCurrentPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setMapCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }); // Set map center to user's current position
            console.log('User location obtained via browser:', position.coords);
          },
          error => {
            console.error('Error getting current location:', error);
            if (error.code === error.PERMISSION_DENIED) {
              setIsGeolocationEnabled(false);
            }
            if (error.code === error.TIMEOUT || error.code === error.POSITION_UNAVAILABLE) {
              console.log('Retrying to get location...');
              setTimeout(() => getCurrentLocation(attemptsLeft - 1), 5000);
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 0,
          },
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    } catch (error) {
      console.error('Error getting current location:', error);
      setTimeout(() => getCurrentLocation(attemptsLeft - 1), 5000);
    }
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const addUserMarker = () => {
    if (isLoaded && currentPosition && mapRef.current) {
      if (userMarkerRef.current) {
        userMarkerRef.current.setMap(null);
      }

      userMarkerRef.current = new window.google.maps.Marker({
        position: currentPosition,
        map: mapRef.current,
        icon: {
          url: `https://maps.google.com/mapfiles/ms/icons/orange-dot.png`,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        label: {
          text: 'You are here',
          color: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
        },
        title: 'Your Location',
      });

      mapRef.current.setCenter(currentPosition);
    }
  };

  useEffect(() => {
    if (currentPosition) {
      addUserMarker();
    }
  }, [isLoaded, currentPosition]);

  const addRestaurantMarkers = () => {
    if (isLoaded && restaurants && restaurants.length > 0 && geocoderRef.current) {
      console.log('Starting geocode for restaurants...');

      restaurants.forEach((restaurant, index) => {
        geocoderRef.current.geocode({ address: restaurant.address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const marker = new window.google.maps.Marker({
              position: results[0].geometry.location,
              map: mapRef.current,
              icon: {
                url: FoodFightIcon,
                scaledSize: new window.google.maps.Size(32, 32),
              },
            });

            marker.addListener('click', () => {
              const restaurantPosition = results[0].geometry.location.toJSON();
              console.log('Restaurant marker clicked:', restaurant.name);
              setSelectedRestaurant({
                ...restaurant,
                position: restaurantPosition,
              });
              if (!isGeolocationEnabled) {
                setMapCenter(restaurantPosition);
                mapRef.current.setCenter(restaurantPosition);
              }
            });

            restaurantMarkersRef.current[index] = marker;
          } else {
            console.error(`Geocoding failed for address: ${restaurant.address} with status: ${status}`);
          }
        });
      });
    }
  };

  useEffect(() => {
    addRestaurantMarkers();
  }, [isLoaded, restaurants]);

  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const defaultZoom = 3;

  if (loadError) {
    console.error('Error loading Google Maps API:', loadError);
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={currentPosition ? 15 : defaultZoom}
        center={mapCenter}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: true,
        }}
        onLoad={mapInstance => {
          mapRef.current = mapInstance;
          if (currentPosition) {
            addUserMarker();
          }
        }}
      />
      {selectedRestaurant && (
        <CustomMapsPopup
          selectedRestaurant={selectedRestaurant}
          map={mapRef.current}
          onClose={() => setSelectedRestaurant(null)}
        />
      )}
    </>
  );
};

export default RestaurantMap;
