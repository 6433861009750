import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { IonButton } from '@ionic/react';
import './styles/CustomMapsPopup.scss';
import { AppDispatchContext, ACTIONS } from '../global/AppContext';

const CustomMapsPopup = ({ selectedRestaurant, map, onClose }) => {
  const appDispatchCtx = useContext(AppDispatchContext);
  const history = useHistory();

  if (!selectedRestaurant || !map) return null;

  const handleSelectRestaurant = () => {
    appDispatchCtx.dispatch({
      action: ACTIONS.SET_RESTAURANT,
      restaurant: selectedRestaurant,
    });
    onClose();
    setTimeout(() => {
      history.push('/app/home');
    }, 1000);
  };

  return (
    <div className="custom-popup">
      <button type="button" className="close-button" onClick={onClose}>
        &times;
      </button>
      <img src={selectedRestaurant.icon} alt={selectedRestaurant.name} className="popup-image" />
      <h3 className="popup-title">{selectedRestaurant.name}</h3>
      <p className="popup-address">{selectedRestaurant.address}</p>
      <div className="popup-footer">
        <IonButton className="popup-button" expand="block" onClick={handleSelectRestaurant}>
          Select this restaurant
        </IonButton>
      </div>
    </div>
  );
};

export default CustomMapsPopup;
