import React, { useEffect, useState, useContext } from 'react';
import { Client } from '@amityco/ts-sdk';
import './UnreadCountPopup.scss';
import { AuthContext } from '../authentication/AuthContext';
import { AppDispatchContext, ACTIONS } from '../../global/AppContext';
import logoIcon from '../../assets/tmp_logo_icon.svg';

const UnreadCountPopup = () => {
  const { dispatch } = useContext(AppDispatchContext);
  const { accessData, idData } = useContext(AuthContext);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isVisible, setisVisible] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    let liveObjectInstance;

    const handleLiveObject = liveObject => {
      if (liveObject.data) {
        const { unreadCount: updatedUnreadCount } = liveObject.data;
        setUnreadCount(updatedUnreadCount);
        dispatch({ action: ACTIONS.SET_UNREAD_COUNT, unreadCount: updatedUnreadCount });
        setisVisible(true);
        setTimeout(() => setAnimateOut(true), 2000);
      }
    };

    const connectClientAndObserveUnreadCount = async () => {
      try {
        if (!Client.isConnected()) {
          await Client.login({ userId: idData.sub, displayName: idData.name });
        }
        liveObjectInstance = Client.getUserUnread(handleLiveObject);
      } catch (error) {
        console.error('Client connection failed:', error);
      }
    };

    if (idData && accessData) {
      connectClientAndObserveUnreadCount();
    }

    return () => {
      if (liveObjectInstance) {
        liveObjectInstance.off();
      }
    };
  }, [idData, accessData]);

  useEffect(() => {
    if (animateOut) {
      setTimeout(() => {
        setisVisible(false);
        setAnimateOut(false);
      }, 1000);
    }
  }, [animateOut]);

  const messageText = unreadCount === 1 ? `You have 1 unread message` : `You have ${unreadCount} unread messages`;

  return (
    isVisible && (
      <div className={`custom-toast ${animateOut ? 'slide-out' : 'slide-in'}`}>
        <img src={logoIcon} alt="Logo" className="toast-logo" />
        <div className="toast-message">{messageText}</div>
      </div>
    )
  );
};

export default UnreadCountPopup;
