import React, { useEffect, useContext, useRef, useState } from 'react';
import {
  IonModal,
  IonContent,
  IonHeader,
  IonButton,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonIcon,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import '../../styles/home/BetDetailModal.scss';
import '../../styles/fight/summary/GamePanel.scss';
import { acceptFight, createPayment } from '../../global/request/bet';
import { getPastAddresses } from '../../global/request/user';
import { AuthContext } from '../authentication/AuthContext';
import { AppContext } from '../../global/AppContext';
import BetStripeForm from './BetStripeForm';
import AddressFields from '../addressFields/AddressFields';
import { parseFormattedAddress } from '../../global/util';

const ModalAfterEarlyReturn = ({
  bet,
  isOpen,
  onClose,
  modalAnimationClass,
  onFightClick,
  formatName,
  // eslint-disable-next-line no-unused-vars
  pendingFight,
}) => {
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);

  const [address, setAddress] = useState(appCtx?.restaurant?.address?.trim() || '');
  const [addressDetails, setAddressDetails] = useState({
    streetAddress: '',
    apt: '',
    city: '',
    state: '',
    zip: '',
  });
  const [pastAddresses, setPastAddresses] = useState([]);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const stripeFormRef = useRef(null);
  const [clientSecret, setClientSecret] = useState('');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  const {
    // eslint-disable-next-line no-unused-vars
    makerName,
    restaurantItems,
    makerOutcomeTeamColor,
    takerOutcomeTeamColor,
    makerTeamAcronym,
    takerTeamAcronym,
    takerOutcomePhrase,
  } = bet;

  const fetchPastAddresses = async () => {
    setPastAddresses(await getPastAddresses(authCtx.tokens.idToken));
  };

  useEffect(() => {
    // A line in Restaurant.js uses both 44 and 64 as delivery IDs
    // Logging the restaurants list also suggests this. Unsure if this is outdated
    if (appCtx?.restaurant?.id !== 44 && appCtx?.restaurant?.id !== 64) {
      return;
    }

    if (!isAddressValid) {
      setAddress('');
      return;
    }

    setAddress(
      `${addressDetails.apt} ${addressDetails.streetAddress} ${addressDetails.city} ${addressDetails.state} ${addressDetails.zip}`,
    );
  }, [isAddressValid, addressDetails]);

  const submitForm = async () => {
    if (stripeFormRef.current) {
      await stripeFormRef.current.requestSubmit();
    }
  };

  const sendPaymentInfo = changedId => {
    setPaymentId(changedId);
  };

  const handleFightClick = async () => {
    if (hasSubmitted) return;
    const inlineDetails = `${addressDetails.apt} ${addressDetails.streetAddress} ${addressDetails.city} ${addressDetails.state} ${addressDetails.zip}`;
    if (inlineDetails.trim() === '') {
      setAddressError(true);
      console.log('address is empty');
      return;
    }
    setHasSubmitted(true);
    try {
      // trigger stripe form with single button
      if (bet.restaurantId === 64) {
        await submitForm();
      } else {
        if (onFightClick) {
          await onFightClick(bet.id, paymentId, inlineDetails.trim());
        }

        setTimeout(() => {
          acceptFight(authCtx.tokens.idToken, bet.id, paymentId, inlineDetails.trim());
          onClose();
        }, 1000);
      }
    } catch (error) {
      console.log('Error during submission', error);
      setHasSubmitted(false);
    }
  };

  const sendPayment = async () => {
    if (bet.restaurantId === 64) {
      let totalAmount = 0;
      restaurantItems.forEach(item => {
        totalAmount += item.cost;
      });
      const payment = await createPayment(authCtx.tokens.idToken, totalAmount);

      setClientSecret(payment);
    }
  };

  useEffect(() => {
    sendPayment();

    const initialAddressDetails = parseFormattedAddress(address);
    if (!initialAddressDetails.invalid) {
      setAddressDetails(initialAddressDetails);
    }
    fetchPastAddresses();
  }, []);

  useEffect(() => {
    if (paymentId !== '') {
      if (onFightClick) {
        onFightClick(bet.id, paymentId, address);
      }

      setTimeout(() => {
        acceptFight(authCtx.tokens.idToken, bet.id, paymentId, address);
        onClose();
      }, 1000);
    }
  }, [paymentId]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className={modalAnimationClass}>
      <IonHeader className="bet-details_modal_header">
        <IonToolbar className="bet-details_modal_header_toolbar">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle className="bet-details_title bet-details_font">FIGHT SUMMARY</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={closeOutline} className="close" slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="details-modal-ion-content">
        <div className="content-wrapper">
          <div className="bet-details_font bet-details_header">MATCHUP</div>
          <div
            className="game-panel"
            style={{
              '--left-color': takerOutcomeTeamColor,
              '--right-color': makerOutcomeTeamColor,
            }}
          >
            <div className="bet-details_teams">
              <div className="bet-details_leftTeam team" style={{ background: takerOutcomeTeamColor }}>
                <p>YOU</p>
                <h1 className="bet-details_font">{takerTeamAcronym}</h1>
              </div>
              <div className="gap">
                <div className="panel-vs-circle">
                  <h1 className="bet-modal-circle">vs</h1>
                </div>
              </div>
              <div className="bet-details_rightTeam team" style={{ background: makerOutcomeTeamColor }}>
                <p>{formatName(makerName)}</p>
                <h1 className="bet-details_font">{makerTeamAcronym}</h1>
              </div>
            </div>
          </div>
          <div className="bet-details_font">YOUR OUTCOME</div>
          <div className="bet-details_outcome">{takerOutcomePhrase}</div>
          <div>
            <div>
              <div className="bet-details_font">Fight Cart</div>
              <ul className="bet-details_list">
                {restaurantItems.map(item => (
                  <li key={item.menu_item_id}>
                    <div className="fight-items">
                      <div className="item-name">{item.name}</div>
                      {item.cost > 0 ? <div className="item-price">${item.cost.toFixed(2)}</div> : <div />}
                      <div className="item-quantity">{item.quantity}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {pendingFight === 1 && bet.restaurantId === 64 && (
            <div className="address-container">
              <div className="bet-details_font">Address</div>
              <AddressFields
                addressDetails={addressDetails}
                setAddressDetails={setAddressDetails}
                setIsFormValid={setIsAddressValid}
                showAutocompleteDefault={false}
                pastAddresses={pastAddresses}
              />
              {addressError && <div className="error-message">Address is required</div>}
            </div>
          )}
          {clientSecret && bet.restaurantId === 64 ? (
            <div>
              <div className="bet-details_font">Payment Information</div>
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <BetStripeForm formRef={stripeFormRef} sendPaymentInfo={sendPaymentInfo} />
                </Elements>
              )}
              <p className="disclaimer-text">
                By proceeding you authorize a 48 hr hold on your card for the amount listed in the Fight Cart. Win =
                authorization canceled; Lose = authorization processed.
              </p>
            </div>
          ) : (
            <div />
          )}
          <IonButton className="fight-button" type="button" onClick={handleFightClick} disabled={hasSubmitted}>
            Fight
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

const BetDetailsModal = ({ bet, isOpen, onClose, modalAnimationClass, onFightClick, formatName, pendingFight }) => {
  if (!bet) return null;
  return (
    <ModalAfterEarlyReturn
      bet={bet}
      isOpen={isOpen}
      onClose={onClose}
      modalAnimationClass={modalAnimationClass}
      onFightClick={onFightClick}
      formatName={formatName}
      pendingFight={pendingFight}
    />
  );
};

export default BetDetailsModal;
