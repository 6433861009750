/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  IonModal,
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonSegment,
  IonLabel,
  IonSegmentButton,
  IonList,
  IonItem,
  IonAvatar,
  IonBadge,
} from '@ionic/react';
import '../../../styles/home/notificationModal.scss';
import { closeOutline } from 'ionicons/icons';
import { MessageRepository, Client, ChannelRepository } from '@amityco/ts-sdk';
import { useHistory, useLocation } from 'react-router-dom';
import { allUsers } from '../../../global/request/user';
import { AuthContext } from '../../authentication/AuthContext';
import FoodFightIcon from '../../../assets/foodfight_red_primary_05.png';
import { login } from '../../../services/amityClient';
import { RecentChatItemLoader } from '../../skeletalLoaders/SkeletalLoaders';
import generateUniqueKey from '../../../UniqueKeyGenerator/UniqueKeyGenerator';
// Mockup Data
import { generateTestNotifications } from './MockupData';
import RecentChats from '../../chat/RecentChats';

const RenderList = ({ title, items, onItemClick }) => (
  <div>
    {items.length > 0 && (
      <>
        <h2 className="time-header">{title}</h2>
        <IonList lines="none">
          {items.map(item => (
            <IonItem key={item.id} className="msg-item" button onClick={() => onItemClick(item.channelId, item.sender)}>
              <IonAvatar slot="start" className="msg-avatar">
                <img src={FoodFightIcon} alt={`${item.sender}'s avatar`} />
              </IonAvatar>
              <IonLabel>
                <h3 className="msg-header">{item.sender}</h3>
                <p className="msg-content">{item.message}</p>
                <p className="msg-time">{item.time}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </>
    )}
  </div>
);

const HomepageNotificationModal = ({ isOpen, onClose, unreadCount }) => {
  const [selectedSegment, setSelectedSegment] = useState('notifications');
  const authCtx = useContext(AuthContext);
  const { idData } = useContext(AuthContext);
  const messageSubscriptionRef = useRef(null);
  const liveCollectionRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const ensureLogin = async () => {
    if (authCtx.accessData && authCtx.accessData.sub && authCtx.tokens.idToken) {
      try {
        await login(authCtx.accessData.sub, authCtx.idData.name, authCtx.tokens.idToken);
      } catch (error) {
        console.error('Login failed in Notification Center:', error);
      }
    }
  };

  const ensureClientConnected = async () => {
    if (!Client.isConnected()) {
      try {
        await Client.startSession({ userId: authCtx.userData.id, displayName: authCtx.userData.name });
      } catch (error) {
        console.error('Client connection failed:', error);
      }
    }
  };

  const classifyTime = dateString => {
    const today = new Date();
    const itemDate = new Date(dateString);

    const timeDifference = today - itemDate; // Difference in milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    const oneWeek = 7 * oneDay;

    if (timeDifference < oneDay) {
      return 'today';
    }
    if (timeDifference < oneWeek) {
      return 'lastWeek';
    }
    return 'earlier';
  };

  const organizeByTime = (data, suffix) => {
    const timeCategories = {
      today: [],
      lastWeek: [],
      earlier: [],
    };

    Object.values(data).forEach(item => {
      timeCategories[classifyTime(item.classifyTime)].push(item);
    });

    Object.keys(timeCategories).forEach(key => {
      timeCategories[key].sort((a, b) => new Date(b.time) - new Date(a.time));
    });

    return Object.fromEntries(Object.entries(timeCategories).map(([key, items]) => [`${key}${suffix}`, items]));
  };

  const notificationExamples = generateTestNotifications();
  const { todayNot, lastWeekNot, earlierNot } = organizeByTime(notificationExamples, 'Not');

  const fetchMessages = async subChannelId => {
    if (messageSubscriptionRef.current === subChannelId) {
      return;
    }

    if (messageSubscriptionRef.current) {
      messageSubscriptionRef.current();
    }

    await ensureClientConnected();

    const liveCollection = MessageRepository.getMessages(
      {
        subChannelId,
        limit: 50,
        reverse: true,
      },
      ({ data: fetchedMessages, error }) => {
        if (error) {
          console.error('Error fetching messages:', error);
          return;
        }

        if (fetchedMessages) {
          const unreadMessages = fetchedMessages.filter(message => message.readCount === 0);
          const friendMessages = unreadMessages.filter(message => message.creatorId !== idData.sub);
          const sortedMessages = friendMessages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setMessages(prevMessages => ({
            ...prevMessages,
            [subChannelId]: sortedMessages,
          }));
        }
      },
    );

    liveCollectionRef.current = liveCollection;
  };

  useEffect(() => {
    if (isOpen) {
      ensureLogin();
    }
  }, [isOpen]);

  const handleChannelClick = (channelId, displayName) => {
    fetchMessages(channelId);
    history.push(location.pathname);
    history.replace(`/chat/${channelId}`, { friendName: displayName, previousPath: location.pathname });
    // history.replace(`/chat/${channelId}`, { friendName: displayName });
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className="notification-modal">
      <IonHeader>
        <IonToolbar className="toolbar">
          <IonTitle className="ion-no-padding">
            <div className="ion-text-wrap">Notification Center</div>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="segments">
          <IonSegment
            mode="md"
            className="notification-segment"
            value={selectedSegment}
            onIonChange={e => {
              console.log('Selected segment:', e.detail.value); // Debug log
              setSelectedSegment(e.detail.value);
            }}
          >
            <IonSegmentButton value="notifications" className="segment-btn">
              <IonLabel>Notifications</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="chat" className="segment-btn">
              <div className="label-badge-container">
                <IonLabel className="chat-label">Chat</IonLabel>
                {unreadCount > 0 && (
                  <IonBadge color="danger" className="recent-badge">
                    {unreadCount}
                  </IonBadge>
                )}
              </div>
            </IonSegmentButton>
          </IonSegment>
        </div>

        {/* Conditionally render either the skeleton loader or the content */}
        {loading ? (
          <div>
            <h2 className="loader-time-header">Today</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
            <h2 className="loader-time-header">Last Week</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
            <h2 className="loader-time-header">Earlier</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
          </div>
        ) : (
          <div className="tab-content">
            {selectedSegment === 'notifications' && (
              <>
                <RenderList title="Today" items={todayNot} onItemClick={() => {}} />
                <RenderList title="Last Week" items={lastWeekNot} onItemClick={() => {}} />
                <RenderList title="Earlier" items={earlierNot} onItemClick={() => {}} />
              </>
            )}
          </div>
        )}
        {selectedSegment === 'chat' && <RecentChats handleChannelClick={handleChannelClick} />}
      </IonContent>
    </IonModal>
  );
};

export default HomepageNotificationModal;
