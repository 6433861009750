import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../components/authentication/AuthContext';
import getEntries from './request/carousel';

import carouselImage1x1280x800 from '../components/home/carousel/1280x800/1.png';
import carouselImage2x1280x800 from '../components/home/carousel/1280x800/2.png';
import carouselImage3x1280x800 from '../components/home/carousel/1280x800/3.png';
import carouselImage4x1280x800 from '../components/home/carousel/1280x800/4.png';
import carouselImage5x1280x800 from '../components/home/carousel/1280x800/5.png';
import carouselImage6x1280x800 from '../components/home/carousel/1280x800/6.png';
import Marz1280x800 from '../components/home/carousel/1280x800/Marz.png';

const FALLBACK_IMAGES = [
  {
    key: 0,
    caption: '',
    pngUrls: [carouselImage1x1280x800],
  },
  {
    key: 1,
    caption: '',
    pngUrls: [carouselImage2x1280x800],
  },
  {
    key: 2,
    caption: '',
    pngUrls: [carouselImage3x1280x800],
  },
  {
    key: 3,
    caption: '',
    pngUrls: [carouselImage4x1280x800],
  },
  {
    key: 4,
    caption: '',
    pngUrls: [carouselImage5x1280x800],
  },
  {
    key: 5,
    caption: '',
    pngUrls: [carouselImage6x1280x800],
  },
  {
    key: 6,
    caption: '',
    pngUrls: [Marz1280x800],
  },
];

/**
 * Hook that returns all images to go on the carousel.
 * @returns {[Function, Object[]]} A function to reload the images, the menu itself, and the categories of the menu
 */
function useCarousel() {
  const authCtx = useContext(AuthContext);

  const [jsonEntries, setJsonEntries] = useState([]);

  const reload = async () => {
    if (!authCtx.tokens) return;

    const bearerToken = authCtx.tokens.idToken;

    try {
      const response = await getEntries(bearerToken);
      let hasInvalidEntries = false;

      const parsedResult = response.map((entry, idx) => {
        if (!entry.pngs || entry.pngs.length === 0) {
          hasInvalidEntries = true;
          return '';
        }
        return {
          key: idx,
          caption: entry.caption,
          pngUrls: entry.pngs.map(png => `data:image/png;base64,${png.data}`),
        };
      });

      const result = hasInvalidEntries ? parsedResult.filter(str => str) : parsedResult;

      setJsonEntries(result.length > 0 ? result : FALLBACK_IMAGES);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Failed to load carousel images, loading fallback images');
      }
      setJsonEntries(FALLBACK_IMAGES);
    }
  };

  useEffect(() => {
    reload();
  }, []);

  return [reload, jsonEntries];
}

export default useCarousel;
